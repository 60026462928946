import React from "react";
import HeroImage from "../components/heroImage";
import CaveImg from "../assets/iNeedToGo/ElectricSheepWeb01.gif";
import EddieWalk from "../assets/iNeedToGo/PC_walk.gif";
import { Link } from "react-router-dom";

export default function projects() {
  return (
    <div>
      <HeroImage />
        <div className="flex items-start md:items-start justify-center h-[70vh]">
      <Link to="/i-need-to-go">
          <div className="bg-slate-800 bg-opacity-60 rounded-lg text-center hover:scale-105 transition-all">
            <div className="text-thisGray text-headSize2 font-Heading">
              I Need To <span className="text-red-600">Go</span>
            </div>
            <div className="text-paragraphSize1 font-Paragraph text-thisGreen">
              A nostalgic point-and-click game<br></br> where humor meets the
              heart.
            </div>
            <div className="relative">
              <div className="absolute text-white left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] font-Heading md:text-[1.5rem] bg-slate-900 bg-opacity-80 rounded-full px-4">
                IN PROGRESS
              </div>
              <div className="absolute text-white left-[90%] top-[0%] translate-x-[-50%] translate-y-[-50%]">
                <img src={EddieWalk} alt="Eddie walking"/>
              </div>
              <img src={CaveImg} alt="big broken house" className="md:h-[400px] p-4 object-contain" />
            </div>
            <div className="text-thisGreen pb-4">
              Click to learn more
            </div>
          </div>
      </Link>
        </div>
    </div>
  );
}

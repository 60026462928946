import React from "react";
import MagicSheep from "../assets/iNeedToGo/ElectricSheepWeb01.gif";
import MagicalForest from "../assets/BackgroundHero3v2.png"
import Cave from "../assets/iNeedToGo/GateBorder.gif"
import Eddie from "../assets/iNeedToGo/PCwalk200.gif"
import Library from "../assets/iNeedToGo/LibBorder.gif"
import Bartender from "../assets/iNeedToGo/SpritesWeb01.gif"
import { ReactComponent as Discordlogo} from '../assets/icons/brand-discord.svg';
import { ReactComponent as Facebooklogo} from '../assets/icons/brand-facebook.svg';
import { ReactComponent as Xlogo} from '../assets/icons/brand-x.svg';
import { ReactComponent as Youtubelogo} from '../assets/icons/brand-youtube.svg';


function ineedtogo() {
  return (
    <div>
      <div className="-z-10 absolute -top-10">
        <img
          src={MagicalForest}
          alt="background of a mystical forest"
          className="sm:w-[100vw] h-[100vh] object-cover brightness-[0.4]"
        ></img>
      </div>
      <div className="flex flex-col justify-center items-center gap-4 max-w-auto">
        <div className="max-w-auto rounded-md bg-black bg-opacity-70 m-4 md:p-4">
          <div>
            <div className="font-Heading md:text-headSize1 text-headSize1Mobile text-center font-bold text-white">
              I Need To <span className="text-red-600">Go</span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            <div className="p-4 rounded-md text-thisGray">
              <div className="text-paragraphSize1 font-bold font-Heading pb-2">
                Nostalgic point & click adventure game, where humor meets the
                heart.
              </div>
              <div>
                <div>
                  <span className="text-red-600">Genre:</span>{" "}
                  Nostalgic Point-and-click adventure
                </div>
                <div>
                  <span className="text-red-600">Art style:</span>{" "}
                  Pixel Art
                </div>
                <div>
                  <span className="text-red-600">Platform:</span> PC
                  and macOS
                </div>
                <div>
                  <span className="text-red-600">Resolution:</span>{" "}
                  320x200 (scalable)
                </div>
                <div>
                  <span className="text-red-600">Engine:</span> Unreal
                  Engine 5
                </div>
                <div>
                  <span className="text-red-600">Release:</span> 2025
                </div>
              </div>
              {/* SOME links */}
              <div className="pt-4">
                <div className="text-white flex justify-center">
                  Follow the development on:
                </div>
                <div className="flex flex-row justify-evenly text-red-600 py-4">
                  <a
                    href="https://discord.gg/pKGE5dxjAk"
                    target="_blank"
                    rel="noreferrer"
                    className="text-red-600"
                  >
                    <Discordlogo className="text-[#5865F2]" />
                  </a>
                  <a
                    href="https://www.facebook.com/share/18LgDhB1ZW/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-red-600"
                  >
                    <Facebooklogo className="text-[#316FF6]" />
                  </a>
                  <a
                    href="https://www.x.com/rabzzgaming"
                    target="_blank"
                    rel="noreferrer"
                    className="text-red-600"
                  >
                    <Xlogo className="text-slate-300" />
                  </a>

                  <a
                    href="https://www.youtube.com/@AldgardStudio"
                    target="_blank"
                    rel="noreferrer"
                    className="text-red-600"
                  >
                    <Youtubelogo className="text-[#FF0000]" />
                  </a>
                </div>
              </div>
            </div>
            <div className="max-w-[100px]">
              <img src={Eddie} alt="Eddie"/>
            </div>
          </div>
          <div className="flex justify-center pt-4">
            <div className="text-white flex flex-col items-center justify-center gap-4 max-w-[700px]">
              <img src={Cave} alt="cave"/>
              <img src={Library} alt="library"/>
              <img src={MagicSheep} alt="magic sheep"/>
              <img src={Bartender} className="" alt="bartender" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ineedtogo;
